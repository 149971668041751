<template>
    <div style="width: 100%;height: 100%;">
        <div style="width: 490px;height: 156px;background: #FFFFFF;overflow: hidden;">
            <div style="height: 24px;font-size: 18px;color: red;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">*<span style="color: #444444;">案例分类</span></div>
            <div style="width: 430px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px">
                <el-cascader  style="width: 430px;" v-model="TypeList" :options="type_list" placeholder="请选择案例分类"></el-cascader>
            </div>
        </div>
        <div style="width: 490px;height: 100%;background-color: #FFFFFF;margin-top: 10px;">
            <div style="height: 24px;font-size: 18px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">案例封面</div>
            <div style="width: 172px;height: 172px;margin: 12px 30px;">
                <el-upload class="avatar-uploader"  action="https:oss.baigongbao.com" :show-file-list="false" :http-request="upload_img">
                    <img v-if="headUrl" :src="headUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </div>
            <div style="height: 24px;font-size: 18px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">案例金额</div>
            <div style="width: 430px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px">
                <el-input prefix-icon="el-icon-coin" placeholder="请输入案例金额" v-model="amount" type="Number" style="width: 430px;"></el-input>
            </div>
            <div style="height: 24px;font-size: 18px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">案例周期</div>
            <div style="display: flex;margin-top: 12px">
                <div style="width: 205px;height: 56px;margin-left: 30px;">
                    <el-date-picker
                        v-model="initiate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                </div>
            </div>
            <div style="height: 24px;font-size: 18px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">关联服务</div>
            <div style="width: 430px;height: 56px;margin: 12px 30px;">
                <el-select v-model="service" placeholder="请选择" style="width: 430px;">
                    <el-option
                        v-for="item in serviceList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        serviceList: {
            type:Number
        }
    },
    components: {


    },
    computed: {

    },
    watch: {
        serviceList:{
            handler(){
                console.log(this.serviceList)
            }
        }
    },
    data () {
        return {
            TypeList:"",
            type_list:[],
            headUrl: '',
            amount:'',
            //    日期
            initiate:'',
            service:'',
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                }
            },
        }
    },
    mounted:function(){
         this.getTypeList()
    },
    methods:{
        // 产品设备分类
        getTypeList(){
            var that = this;
            that.newApi.getTypeList({
                lv: 3,
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        upload_img:function(params){
            var file = params.file;
            const that = this;
            this.utils.upload(file,function(url){
	            if (!url){
		            return false;
	            }
                that.headUrl = url.url;
            })
        },

    }
}
</script>
<style scoped>
/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-top:10px;
}
/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
/deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 168px;
    height: 158px;
    line-height: 178px;
    text-align: center;
}
/deep/ .avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
