<template>
    <div style="width: 100%">
        <!-- 头部  -->
        <div style="width: 100%;height: 60px;background: #FFFFFF;overflow: hidden;">
            <div style="float: left">
                <img src="../../assets/imgs/index/log1.png" style="width: 90px;height: 28px;margin: 16px 0 0 20px;">
            </div>
            <div style="margin-top: 17px;display: flex;justify-content: center;text-align: center">
                <div style="height: 21px;font-size: 18px;color: #444444;font-weight:bold;">发布案例</div>
            </div>
        </div>
        <div style="width:100%;display: flex;justify-content: center;">
            <!--  左侧  -->
            <div style="width: 1000px;background-color: #FFFFFF;height:100%;margin-top: 10px;">
                <div style="height: 24px;font-size: 18px;color: red;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">*<span style="color: #444444;">案例标题</span></div>
                <div style="width: 940px;height: 56px;margin: 12px 30px">
                    <el-input v-model="title" placeholder="请输入标题"></el-input>
                </div>
                <div style="height: 24px;font-size: 18px;color: red;margin: 0 0 0 30px;font-weight: bold;">*<span style="color: #444444;">案例介绍</span></div>
                <div style="width: 940px;height: auto;background: #FFFFFF;margin-top:12px;margin-left: 30px;margin-bottom: 22px;">
                    <Editor :tinymce_height="'680'"  :innerDrawer = "innerDrawer = true" :value="content"  ref="ch"></Editor>
                </div>
            </div>
            <div style="width: 490px;height: 100%;margin-left: 10px;margin-top: 10px;">
                <caseContent :serviceList="serviceList" ref="childDatas"></caseContent>
            </div>
        </div>
        <!--  底部 -->
        <div style="width: 100%;overflow: hidden;">
            <div style="width: 100%;height: auto;background: #FFFFFF;display: flex;justify-content: center;align-content: center;padding-top: 28px;margin-top: 5px;">
                <div style="width:130px;height: 38px;background: #F0F2F5;border-radius: 5px 5px 5px 5px;cursor: pointer">
                    <el-button style="width:130px;" type="info">保存草稿</el-button>
                </div>
                <div v-if="typeof id != 'undefined' " @click="addcase"  style="margin-left: 40px;cursor: pointer">
                    <el-button type="primary">保存</el-button>
                </div>
                <div v-else style="width:130px;height: 38px;background: #2970FF;margin-left: 40px;border-radius: 5px 5px 5px 5px;cursor: pointer">
                    <el-button style="width:130px;"  type="primary" @click="addcase">发布案例</el-button>
                </div>
                <div style="height: 50px"></div>
            </div>
        </div>
    </div>
</template>
<script>
import caseContent from "./components/caseContent";
import Editor from "@/components/common/Editor";
export default{
    components: {
        caseContent,
        Editor

    },
    computed: {

    },
    data () {
        return {
            title:"",
            content:"",
            serviceList:[],
            id:this.$route.query.id,
        }
    },
    mounted:function(){
         this.getMyShop();
         this.getShopCaseInfo()
    },
    methods:{
        addcase(){
            let that = this;
            let params = {};
            if(that.title == ""){
                that.utils.err("请填写标题");
                return false;
            }
            params.title = that.title;
            let chil = that.$refs.ch;
            let content = chil.get_content();
            content = content.replace(/amp;/g,"");
            params.content = content;
            if(params.content == ""){
                that.utils.err("请填写内容");
                return false;
            }
            if(that.$refs.childDatas.TypeList == ""){
                that.utils.err("分类不能为空");return false
            }
            params.type = JSON.stringify(that.$refs.childDatas.TypeList);

            if(that.$refs.childDatas.headUrl == ""){
                that.utils.err("封面不能为空");return false
            }
            params.img = that.$refs.childDatas.headUrl;
            if(that.$refs.childDatas.amount == ""){
                that.utils.err("金额不能为空");return false
            }
            params.money = that.$refs.childDatas.amount;
            if(that.$refs.childDatas.initiate == ""){
                that.utils.err("日期不能为空");return false
            }
            params.time = that.$refs.childDatas.initiate;
            if(that.$refs.childDatas.service == ""){
                that.utils.err("关联服务不能为空");return false
            }
            params.serviceId = that.$refs.childDatas.service;

            if (typeof this.$route.query.id != 'undefined'){
                //    更新发布方法
                params.id = that.$route.query.id
                that.newApi.updateShopCase(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data)
                        that.$router.push("/shop/shopCode/")
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            } else {
                params.shopId = that.myshop.id
                that.newApi.addShopCase(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data)
                        that.$router.push("/shop/shopCode/")
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }
        },
        // 获取我的店铺
        getMyShop(){
            const that = this;
            that.newApi.getMyShop({}).then((ret)=>{
                that.myshop = ret.data;
                this.getShopServiceList()
            }).catch((err)=>{
                console.log(err)
            })
        },
        //    查询商品服务列表
        getShopServiceList(){
            var that= this;
            that.newApi.getShopServiceList({
                shopId:that.myshop.id,
                page:1,
                pageSize:10,
            }).then((ret)=>{
                that.serviceList = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        getShopCaseInfo:function (){
            var that= this;
            that.newApi.getShopCaseInfo({
                id:that.$route.query.id,
            }).then((ret)=>{
                that.title = ret.data.title;
                that.content = ret.data.content;
                that.$refs.childDatas.TypeList = ret.data.type;
                that.$refs.childDatas.headUrl = ret.data.img;
                that.$refs.childDatas.amount = ret.data.money;
                that.$refs.childDatas.initiate = ret.data.time.split(',');
                that.$refs.childDatas.service = ret.data.serviceId;
            }).catch((err)=>{
                console.log(err)
            })
        },

    }
}
</script>